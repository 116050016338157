<template>
  <v-container fluid>
    <editor
      :initial="dataItem"
      :seriesData="seriesArray"
      :defaultSeries="defaultSeries"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :objectName="objectName"
      @data="save"
    ></editor>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_componets/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      docStatus: true,
      docCreate: false,
      docCopyTo: true,
      seriesArray: [],
      defaultSeries: {},
      closePageConfirmationDialog: false,
      objectName: "Service Call",
      dataItem: {},
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getServiceCall",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      const url = `/dimensions/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          self.$router.push("/cost-accounting/dimensions");
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getServiceCall(val) {
      const self = this;
      this.$store
        .dispatch("get", `/equipment-cards/${val}`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.dataItem = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    formSettings() {
      this.activeObject = 176;
      const objectId = 176;
      const self = this;

      this.$store
        .dispatch("get", `/form_settings/${objectId}`)
        .then((res) => {
          self.setup = res;
          self.defaultSeries = res.DfltSeries;
          self.seriesArray = res.Series;
          self.usersArray = res.users;
          self.departmentsArray = res.departments;
          self.BPLId = res.BPLId;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
  },
  created() {
    this.formSettings();
  },
};
</script>

<style lang="scss" scoped>
</style>